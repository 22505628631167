import LocomotiveScroll from 'locomotive-scroll';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

// Detect mobile device (Do not remove!!!)
var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Nokia|Opera Mini/i.test(navigator.userAgent) ? true : false;

// Add class "is-mobile" to </body>
if(isMobile) {
    document.querySelector("body").classList.add("is-mobile");
}
const scroller = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true
});

scroller.on('scroll', (args) => {

    if (args.scroll.y > 250) {


    } else {


    }
});


// Initialize and add the map
function initMap() {

    const location = { lat: 41.08580560003138, lng: 29.008584128374974 };

    const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: location,
    });

    const marker = new google.maps.Marker({
        position: location,
        map: map,
    });
}
initMap()

if(isMobile) {
    document.querySelector(".hambuger-menu").addEventListener("click",(e)=> {
        const mobileMenu = document.querySelector(".mobile-menu")
        if(mobileMenu.classList.contains("-top-full")) {
            mobileMenu.classList.remove("-top-full")
            mobileMenu.classList.add("-top-0")
            e.currentTarget.classList.add("open");
        }
        else {
            mobileMenu.classList.add("-top-full")
            mobileMenu.classList.remove("-top-0")
            e.currentTarget.classList.remove("open");
        }
    })

    document.querySelector(".mobile-menu").querySelectorAll("a").forEach((item)=> {
        item.addEventListener("click",()=> {

            document.querySelector(".hambuger-menu").click();

        })
    })
}
else {
    document.querySelector(".menu").querySelectorAll("a").forEach(item => {
        item.addEventListener("click",e => {
            e.preventDefault();
            const target = document.querySelector(e.currentTarget.getAttribute("href"));
            setMenuActive(e.currentTarget);
            scroller.scrollTo(target);

        })

    })
}

function setMenuActive(target) {
    document.querySelector(".menu").querySelectorAll("a").forEach(item => {
        item.classList.remove("active")
    })
    target.classList.add("active")
}

